import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useMobile } from "../../hooks/useMobile";
import NtFooterBar from "./NtFooterBar";

function NtRootPageContainer({
  children,
  pageTitle,
  pageSubtitle,
  renderTitleView,
  renderFilter,
  showFooter = false,
  showNavigation = true,
  showSidePannel = false,
  sidePannelContainerStyle = 0,
  isLoading,
  scrollEnabled = true,
}) {
  const isMobile = useMobile();
  const maxWidth = 850;
  const sidePannelWidth = 245;

  return (
    <View style={[styles.container]}>
      <ScrollView
        scrollEnabled={scrollEnabled}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View
          style={{ flex: 1, justifyContent: "center", flexDirection: "row" }}
        >
          <View
            style={{
              padding: isMobile ? 15 : 20,
              flex: 1,
              maxWidth: maxWidth,
            }}
          >
            {renderFilter && (
              <View style={{ marginTop: 10 }}>{renderFilter}</View>
            )}

            {!isLoading && children}
          </View>
        </View>

        {showFooter && <NtFooterBar />}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
    backgroundColor: "white",
  },
});

export default NtRootPageContainer;
