import React, { useContext, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import UserContext from "../../context/UserContext";
import { useMobile } from "../../hooks/useMobile";
import { useNavigation } from "@react-navigation/native";
import NtText from "../../components/NtText";
import NtButton from "../../components/NtButton";

function NavigationBar({ navItems, currentController, onNavPress }) {
  const context = useContext(UserContext);
  // const [selection, setSelection] = useState(navItems[0].route);
  const nav = useNavigation();
  const isMobile = useMobile();

  return (
    <View style={styles.container}>
      <View>
        {/* <View style={{ width: 40, height: 6, backgroundColor: "black" }} />
        <NtText style={[styles.text, { marginTop: 10 }]}>
          Standard Digital
        </NtText> */}

        {/* <NtButton
          containerStyle={{
            width: 120,
            marginTop: 20,
            borderColor: "#88898D",
            borderWidth: 1,
            backgroundColor: "white",
          }}
          textStyle={{ color: "#88898D", fontWeight: "600" }}
          text={"Contact"}
          onPress={() => {
            this.props.navigation.navigate(routes.contact);
          }}
        /> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "white",
    elevation: 4,
    flexDirection: "row",
    paddingRight: 60,
    justifyContent: "flex-end",
    // padding: 20,
  },
  image: {
    height: 40,
    width: 150,
    resizeMode: "contain",
  },
  text: {
    fontSize: 20,
    fontWeight: "bold",
    color: "black",
  },
});

export default NavigationBar;
