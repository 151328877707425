import React from "react";
import { View, StyleSheet } from "react-native";
import AppNavigator from "../../navigation/AppNavigator";
import routes from "../../navigation/routes";
import NavigationBar from "../components/NavigationBar";
import Logger from "../../common/utils/Logger";
import RootController from "./RootController";

class RootAppController extends RootController {
  state = {
    currentController: null,
    isMobile: false,
  };

  componentDidMount() {
    super.componentDidMount();
  }

  showNavigation = () => {
    return this.state.currentController !== routes.notFound;
  };

  render() {
    return (
      <View style={styles.container}>
        {this.showNavigation() && (
          <NavigationBar currentController={this.state.currentController} />
        )}

        <AppNavigator
          onControllerChanged={(controller) => {
            Logger("Current controller: ", controller);
            this.setState({ currentController: controller });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
  },
});

export default RootAppController;
