import { create } from "apisauce";
import settings from "../config/settings";

const clientnotification = create({
  baseURL: settings.apiNotificationUrl,
  headers: { Accept: "application/json" },
});

clientnotification.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept"] = "application/hal+json";
  request.headers["Authorization"] = `Basic ${settings.apiNotificationAuth}`;
});

export default clientnotification;
