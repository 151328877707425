import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";

function NtSectionContainer({
  children,
  backgroundColor = "white",
  containerStyle,
}) {
  return (
    <View
      style={[
        styles.container,
        { backgroundColor: backgroundColor },
        containerStyle,
      ]}
    >
      <View
        style={{
          width: "100%",
          alignItems: "center",
          maxWidth: 820,
        }}
      >
        {children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.darkBlue,
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 40,
    paddingBottom: 40,
  },
});

export default NtSectionContainer;
