import React from "react";
import { View, StyleSheet, TextInput } from "react-native";
import colors from "../config/colors";
import NtMaterialIcon from "./NtMaterialIcon";
import NtBorderTextView from "./NtBorderTextView";

function NtTextInput({
  icon,
  width = "100%",
  bgColor = colors.lighestGray,
  textColor = colors.lighGray,
  borderColor = colors.lighestGray,
  containerStyle,
  ...otherProps
}) {
  return (
    <View
      style={[
        styles.container,
        { width: width, backgroundColor: bgColor, borderColor: borderColor },
        containerStyle,
      ]}
    >
      {icon && (
        <NtMaterialIcon
          style={styles.icon}
          name={icon}
          size={25}
          color={textColor}
        />
      )}
      <TextInput style={[styles.text, { color: textColor }]} {...otherProps} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    flexDirection: "row",
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
  },
  text: {
    marginRight: 10,
    marginLeft: 6,
    flex: 1,
    paddingVertical: 0,
    outlineColor: colors.lighter,
    outlineStyle: "none",
  },
  icon: {},
});

export default NtTextInput;
