//https://reactnavigation.org/docs/configuring-links/#passing-params
//important links: https://reactnavigation.org/blog/2020/05/16/web-support/

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { View, StyleSheet } from "react-native";
import NoFoundController from "../common/controllers/NoFoundController";
import routes from "./routes";
import MarketingController from "../marketing/controllers/MarketingController";
import LearnMoreController from "../marketing/controllers/LearnMoreController";

const Stack = createNativeStackNavigator();

function AppNavigator({ onControllerChanged, initialRoute }) {
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenListeners={{
        state: (e) => {
          const controller =
            e.data.state.routes[e.data.state.routes.length - 1].name;
          if (onControllerChanged) {
            onControllerChanged(controller);
          }
        },
      }}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name={routes.marketing}
        component={MarketingController}
        options={{ title: "Standard Digital | Marketing" }}
      />

      <Stack.Screen
        name={routes.contact}
        component={LearnMoreController}
        options={{ title: "Standard Digital | Contact" }}
      />

      <Stack.Screen
        name={routes.notFound}
        component={NoFoundController}
        options={{ title: "Standard Digital | No Found" }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AppNavigator;
