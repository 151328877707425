import React, { useEffect } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";
import { useNavigation } from "@react-navigation/native";
import { useMobile } from "../../hooks/useMobile";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtFooterBar({ onContactUs }) {
  const nav = useNavigation();
  const isMobile = useMobile();

  useEffect(() => {}, []);

  return (
    <View style={styles.container}>
      <NtText
        style={[styles.text, { textAlign: isMobile ? "center" : "left" }]}
      >
        Standard Digital Group
      </NtText>

      <NtText style={[styles.copyright, { marginTop: 15 }]}>
        Copyright © {new Date().getFullYear()} All Rights Reserved
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "black",
    padding: 30,
  },
  text: {
    color: "white",
    width: "100%",
    fontSize: 15,
    fontWeight: "500",
  },
  copyright: {
    color: colors.lighGray,
    width: "100%",
    fontSize: 13,
    textAlign: "center",
  },
});

export default NtFooterBar;
