import React from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtButton from "../../components/NtButton";
import NtInquirySection from "../components/NtInquirySection";
import NtFooterBar from "../../root/components/NtFooterBar";

class LearnMoreController extends React.Component {
  state = {};
  render() {
    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={{ flex: 1 }} />
          <View
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 30,
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Image
                style={styles.image}
                source={require("../../assets/images/logo.png")}
              />
              <NtText style={styles.logoText}>Standard{"\n"}Digital</NtText>
            </View>
          </View>
          <NtInquirySection />

          <View style={{ flex: 1 }} />
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 80,
    height: 80,
    // resizeMode: "contain",
  },
  logoText: {
    fontSize: 28,
    fontWeight: "500",
    color: "black",
  },
});

export default LearnMoreController;
