export const linking = {
  config: {
    screens: {
      marketing: "",
      contact: "/contact",
      notfound: "*",
    },
  },
};

const routes = {
  marketing: "marketing",
  contact: "contact",
  notFound: "notfound",
};

export default routes;
