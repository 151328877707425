import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtButton from "../../components/NtButton";
import routes from "../../navigation/routes";
import colors from "../../config/colors";

class MarketingController extends React.Component {
  state = {};
  render() {
    return (
      <NtRootPageContainer style={styles.container} showFooter={true}>
        <View style={{ flex: 1 }} />
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: -30,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
              style={styles.image}
              source={require("../../assets/images/logo.png")}
            />
            <NtText style={styles.logoText}>Standard{"\n"}Digital</NtText>
          </View>
        </View>

        <View style={{ flex: 1 }} />

        <View style={{ alignItems: "center" }}>
          <NtButton
            containerStyle={{
              width: 120,
              marginTop: 20,
              borderColor: "#88898D",
              borderWidth: 1,
              backgroundColor: "white",
            }}
            textStyle={{ color: "#88898D", fontWeight: "600" }}
            textColor="#88898D"
            text={"Contact"}
            onPress={() => {
              this.props.navigation.navigate(routes.contact);
            }}
          />
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 120,
    height: 120,
    // resizeMode: "contain",
  },
  logoText: {
    fontSize: 40,
    fontWeight: "500",
    color: "black",
  },
});

export default MarketingController;
