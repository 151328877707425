const settings = {
  apiUrl: "http://199.193.189.203:30081/v3",
  apiAuth: "bmNsOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",

  stompUrl: "ws://messaging.maritime.ntlk.co:15674/ws",
  messagingService: "nettalkmaritime.com",

  apiNotificationUrl: "https://api.mds.nettalk.com",
  apiNotificationAuth: "b3NjYXJhOnBhc3N3b3JkMUE=",

  pageSizeLoad: 25,
  mobileSize: 880,
};

export default settings;
