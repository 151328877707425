export default {
  darkest: "#323B5B",
  darker: "#5A6795",
  dark: "#808CBA",
  teal: "#BDC5E3",
  lighter: "#D5E0ED",
  lightest: "#E7ECF2",
  white: "#ffffff",
  blue: "#0d58a3",
  lighGray: "#a8a8a8",
  lighestGray: "#f5f5f5",

  lightBlue: "#71A7C9",
  darkBlue: "#0D0C2D",

  //nooz score
  noozscoreSentiment: "#636f9b",
  noozscorePropaganda: "#8792be",
  noozscoreOpinion: "#1e3f93",
  noozscoreRevision: "#3e4665",
  noozscoreGhostEdits: "#B47070",

  //objectivity
  objectivityUnfill: "#bcd1e5",
  objectivity76to100: "#0b2f8a",
  objectivity51to75: "#3c58a1",
  objectivity26to50: "#6d82b9",
  objectivity1to25: "#9eacd0",
  objectivity0: "#bdc5e3",

  //sentiment
  sentimentNegativeUnfill: "#ebb3b3",
  sentimentPositiveUnfill: "#C3E98D",
  sentimentDeactivated: "#D5E0ED",
  sentimentPositiveFilled: "#46AB17",
  sentiment0: "#bdc5e3",
  sentimentNegativeFilled: "#bf3028",

  //revision
  revisionUnfill: "#bcd1e5",
  revision76to100: "#0b2f8a",
  revision51to75: "#3c58a1",
  revision26to50: "#6d82b9",
  revision2to25: "#9eacd0",
  revision1: "#bdc5e3",

  //navigation
  navigationColor: "#080E32",
  navigationTintColor: "#ffffff",
};
