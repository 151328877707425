import clientnotification from "./clientnotification";

export const sendEmail = (to, subject, message, fromName = "me") => {
  const body = {
    recipients: to,
    system: "Maritime",
    from: "noreply@nettalkservices.com",
    fromName: "Maritime Inquiry Request",
    subject: subject,
    message: message,
    messageAlt: "messageAlt",
  };

  return clientnotification.post("/email/send", body);
};
